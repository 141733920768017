<template>
  <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="ShopOrder-container">
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <div class="common-screen-container">
      <div class="common-input-container">
        <span>订单名称:</span>
        <el-input
          class="common-screen-input"
          placeholder="请输入"
          v-model="params.name"
        ></el-input>
      </div>
      <div class="common-input-container">
        <span>所属店铺:</span>
        <el-select
          class="common-screen-input"
          v-model="params.shopId"
          @change="commonFun"
          placeholder="请选择"
          filterable
        >
          <el-option
            v-for="item in shopList"
            :key="item.id"
            :label="item.shop_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>所属客户:</span>
        <el-select
          class="common-screen-input"
          v-model="params.customerId"
          @change="commonFun"
          placeholder="请选择"
          filterable
        >
          <el-option
            v-for="item in custList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>订单状态:</span>
        <el-select
          class="common-screen-input"
          v-model="params.orderStep"
          @change="commonFun"
          placeholder="请选择"
          filterable
        >
          <el-option
            v-for="item in custDict['5']"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <el-button class="common-screen-btn" type="primary" @click="() => shopOrder()"
        >查 询</el-button
      >
      <el-button class="common-screen-btn" plain @click="() => resetBtn()"
        >重 置</el-button
      >
    </div>
    <el-table class="common-table" :data="tableData">
      <el-table-column
        prop="name"
        label="订单名称"
        :formatter="tableColumn"
        width="140x"
      ></el-table-column>
      <el-table-column
        prop="shop_name"
        label="所属店铺"
        :formatter="tableColumn"
        width="120px"
      ></el-table-column>
      <el-table-column
        prop="customer_name"
        label="所属客户"
        :formatter="tableColumn"
        width="120px"
      ></el-table-column>
      <!-- <el-table-column
        prop="phone"
        label="联系方式"
        :formatter="tableColumn"
        width="120px"
      ></el-table-column> -->
      <el-table-column
        prop="order_step_name"
        label="订单状态"
        :formatter="tableColumn"
        width="120px"
      ></el-table-column>
      <el-table-column
        prop="price"
        label="订单金额"
        :formatter="tableColumn"
        width="120px"
      ></el-table-column>
      <el-table-column
        prop="end_time"
        label="服务时间"
        :formatter="tableColumn"
        width="200px"
      ></el-table-column>
      <el-table-column
        prop="note"
        label="备注"
        :formatter="tableColumn"
        width="170px"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="create_name"
        label="创建人"
        :formatter="tableColumn"
        width="120px"
      ></el-table-column>
      <el-table-column
        prop="start_time"
        label="创建时间"
        :formatter="tableColumn"
        width="160px"
      ></el-table-column>
      <el-table-column label="操作" width="120" fixed="right">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
        
            @click="() => addDrawer(scope.row)"
            >新建店铺</el-button
          >
        
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.pageNum"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <shop-order-add
      ref="cusListAdd"
      :tableRow="tableRow"
      :drawer="drawer"
      :platList="platList"
      :finishedList="custList"
      :userList="userList"
      :rolesCompany="rolesCompany"
      @handleClose="handleClose"
      @commonDepartAll="commonDepartAll"
    ></shop-order-add>
  </div>
</template>
<script>
import Breadcrumb from "../../components/common/breadcrumb.vue";
import ShopOrderAdd from "./children/shopOrderAdd.vue";
import { shopOrder, getFinishedList, getPlatList } from "../../service/customer.js";
import {
  commonDict,
  getShopList,
  getCustList,
  commonDepartAll,
} from "../../service/common";
import { getUserList } from "../../service/manage.js";
import { tableColumn, Config } from "../../utils/index.js";
export default {
  components: {
    Breadcrumb,
    ShopOrderAdd,
  },
  data() {
    return {
      tableColumn,
      loading: false,
      rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company, // 所属公司下拉
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "销售", isLink: false },
        { title: "店铺订单", isLink: false },
      ],
      tableRow: "", // 客户id用于编辑/查看
      centerDialogVisible: false, // 修改密码员工弹窗
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100],
      },
      assignPeople: [],
      userList: [],
      comPeopleList: [],
      custDict: {},
      custList: [], // 所属客户下拉
      shopList: [], // 所属店铺下拉
      platList: [],
      finishedList: [],
      params: {
        pageNum: 1,
        pageSize: 10,
      },
      rules: {
        serviceId: [{ required: true, message: "请选择负责人", trigger: "change" }],
      },
      drawer: false, // 新增员工弹窗
      roleSelect: "",
      options: [
        // 筛选下拉
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
      ],
      tableData: [],
    };
  },
  created() {
    this.shopOrder();
    this.commonDict(5);
    this.getCustList();
    this.getShopList();
    this.getUserList();
    this.getFinishedList();
    this.getPlatList();
  },
  methods: {
    commonFun() {
      this.params.pageNum = 1;
      this.params.pageSize = 10;
      this.getShopList();
    },
    async commonDepartAll(id, type) {
      // 部门 下拉
      let resData = (await commonDepartAll({}, id)).data;
      if (type == "cusListAdd") {
        this.$refs[type].getDataInfo(resData);
      } else {
        this.selectDepart = resData ? resData : [];
      }
    },
    async getPlatList() {
      // 所属平台/类目
      let resData = (await getPlatList({ pageNum: -1 })).data;
      this.platList = resData;
    },
    async getFinishedList() {
      // 所属客户下拉
      let resData = (await getFinishedList({ pageNum: -1 })).data;
      this.finishedList = resData.records;
      this.increasing++;
    },
    async getUserList() {
      // 商务负责下拉，暂用员工列表
      let resData = (await getUserList({ pageNum: -1 })).data;
      this.userList = resData;
    },
    resetBtn() {
      // 重置
      this.params = {
        pageNum: 1,
        pageSize: 10,
      };
      this.shopOrder();
    },
    async getShopList() {
      // 所属店铺下拉
      let resData = (await getShopList()).data;
      this.shopList = resData;
    },
    async getCustList() {
      // 所属客户下拉
      let resData = (await getCustList()).data;
      this.custList = resData;
    },
    async commonDict(type) {
      // 字典值
      let resData = (await commonDict({}, type)).data;
      this.custDict[`${type}`] = resData;
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.pageNum = e;
      }
      this.shopOrder();
    },
    async shopOrder() {
      // 客户订单列表
      this.loading = true;
      let params = { ...this.params };
      let resData = (await shopOrder(params)).data;
      this.pagination.total = resData.total;
      this.pagination.pageSize = resData.size;
      this.tableData = resData.records;
      this.loading = false;
    },
    editPassword(e) {
      // 修改密码弹窗
      this.centerDialogVisible = true;
      console.log(e);
    },
    handleClose() {
      this.drawer = false;
      this.shopOrder();
      // 关闭新增员工弹窗
      // done();
    },
    addDrawer(row) {
      //新增员工
      this.drawer = true;
      if (row) {
        this.tableRow = row;
        this.$refs["cusListAdd"].getDataInfo("", row);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.ShopOrder-container {
  text-align: left;
  .nameIcon {
    width: 32px;
    height: 32px;
    color: #f56a00;
    background-color: #fde3cf;
    border-radius: 50%;
    text-align: center;
    line-height: 32px;
  }
}
.dialog-input {
  width: 320px !important;
}
</style>
